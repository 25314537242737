<template>
  <div>
    <DetailTemplate :customClass="'customer-detail detail-page'">
      <template v-slot:sidebar-detail>
        <div class="d-flex align-center">
          <!--  <span class="color-custom-blue font-weight-700 font-size-20 mr-2" v-if="preventiveArr && preventiveArr.customer && preventiveArr.customer.display_name"
          >{{preventiveArr.customer.display_name}}</span
        > -->
          <!-- <v-chip
            label
            color="chip-custom-blue"
            outlined
            class="text-white p-3 mr-2"
            text-color=""
            style="font-size: 21px !important; font-weight: 700"
          >
            {{ preventiveArr?.preventive?.unique_id }}
          </v-chip> -->
          <template
            v-if="
              preventiveArr &&
              preventiveArr.preventive &&
              preventiveArr?.preventive?.status
            "
          >
            <CustomStatus
              :status="preventiveArr?.preventive?.status"
              endpoint="preventive-maintanance/status"
            ></CustomStatus>
          </template>

          <!--  <v-chip
            :color="getColor(preventiveArr.status)"
            label
            v-if="preventiveArr?.preventive?.status"
          >
            <span class="font-size-16 font-weight-500">
              {{ getStatus(preventiveArr?.preventive?.status) }}
            </span>
          </v-chip> -->
        </div>
        <div class="">
          <div class="gray-background pt-0">
            <v-skeleton-loader
              class="custom-skeleton"
              v-if="pageLoading"
              type="text@5"
            >
            </v-skeleton-loader>
          </div>
          <div class="gray-background-- mb-5 mt-4">
            <table width="100%">
              <tr>
                <th
                  colspan="2"
                  class="grey lighten-4 font-size-19 color-custom-blue py-1"
                >
                  Preventive Maintenance Detail
                </th>
              </tr>
              <tr>
                <td
                  valign="middle"
                  class="py-1 font-size-18 font-weight-500"
                  style="width: 120px"
                >
                  PM#
                </td>
                <td valign="middle" class="py-1 font-size-18">
                  <v-chip
                    small
                    outlined
                    color="blue darken-4"
                    label
                    class="text-capitalize"
                  >
                    {{ preventiveArr?.preventive?.unique_id }}
                  </v-chip>
                </td>
              </tr>
              <tr>
                <td valign="top" class="py-1 font-size-18 font-weight-500">
                  Title
                </td>
                <td valign="top" class="py-1 font-size-18">
                  <label class="m-0" v-if="preventiveArr?.preventive?.title">
                    {{ preventiveArr?.preventive?.title }}
                  </label>
                  <em v-else class="text--secondary"> no title</em>
                </td>
              </tr>
              <!-- <tr>
                <td valign="top" class="py-1 font-size-18 font-weight-500">
                  PM No.
                </td>
                <td valign="top" class="py-1 font-size-18">
                  <label
                    class="m-0"
                    v-if="preventiveArr?.preventive?.unique_id"
                  >
                    {{ preventiveArr?.preventive?.unique_id }}
                  </label>
                  <em v-else class="text--secondary"> No PM No</em>
                </td>
              </tr> -->
              <tr>
                <td valign="middle" class="py-1 font-size-18 font-weight-500">
                  Cost
                </td>
                <td valign="middle" class="py-1 font-size-18">
                  <v-chip
                    small
                    outlined
                    color="green darken-4"
                    label
                    class="text-capitalize font-weight-600"
                    v-if="preventiveArr?.preventive?.cost"
                  >
                    ${{ preventiveArr?.preventive?.cost }}
                  </v-chip>
                  <em v-else class="text--secondary"> no cost</em>
                </td>
              </tr>
              <tr>
                <td valign="middle" class="py-1 font-size-18 font-weight-500">
                  Schedule Type
                </td>
                <td valign="middle" class="py-1 font-size-18">
                  <label
                    class="m-0"
                    v-if="preventiveArr?.preventive?.btx_schedule_tab"
                  >
                    <v-chip
                      small
                      outlined
                      color="blue"
                      label
                      class="text-capitalize"
                    >
                      {{
                        preventiveArr.preventive.btx_schedule_tab.replaceAll(
                          "_",
                          " "
                        )
                      }}
                    </v-chip>
                  </label>
                </td>
              </tr>
              <tr>
                <td class="py-1" style="height: 30px"></td>
              </tr>
              <tr>
                <th
                  colspan="2"
                  class="grey lighten-4 font-size-19 color-custom-blue py-1 my-3"
                >
                  PM Contract Date
                </th>
              </tr>
              <tr>
                <td valign="middle" class="py-1 font-size-18 font-weight-500">
                  Start Date
                </td>
                <td valign="middle" class="py-1 font-size-18">
                  <label
                    class="m-0"
                    v-if="preventiveArr?.preventive?.start_date"
                  >
                    <v-chip small outlined color="red" label>
                      {{ formatDate(preventiveArr.preventive.start_date) }}
                      {{
                        formatDateStartTime(preventiveArr.preventive.start_date)
                      }}
                    </v-chip>
                  </label>
                  <em v-else class="text--secondary"> No Start Date</em>
                </td>
              </tr>
              <tr>
                <td valign="middle" class="py-1 font-size-18 font-weight-500">
                  End Date
                </td>
                <td valign="middle" class="py-1 font-size-18">
                  <label class="m-0" v-if="preventiveArr?.preventive?.end_date">
                    <v-chip small outlined color="green" label>
                      {{ formatDate(preventiveArr.preventive.end_date) }}
                      {{
                        formatDateStartTime(preventiveArr.preventive.end_date)
                      }}
                    </v-chip>
                  </label>
                  <em v-else class="text--secondary"> No End Date</em>
                </td>
              </tr>
              <tr>
                <td class="py-1" style="height: 30px"></td>
              </tr>
              <tr>
                <th
                  colspan="2"
                  class="grey lighten-4 font-size-19 color-custom-blue py-1 my-3"
                >
                  Contact Details
                </th>
              </tr>
              <tr v-if="preventiveArr?.propertycontactperson">
                <td colspan="2">
                  <p class="mb-1">
                    {{ preventiveArr?.propertycontactperson?.display_name }}
                  </p>
                  <p class="mb-1">
                    {{ preventiveArr?.propertycontactperson?.primary_phone }}
                  </p>
                  <p class="mb-1">
                    {{ preventiveArr?.propertycontactperson?.primary_email }}
                  </p>
                </td>
              </tr>
              <tr>
                <td class="py-1" style="height: 20px"></td>
              </tr>
              <tr>
                <th
                  colspan="2"
                  class="grey lighten-4 font-size-19 color-custom-blue py-1"
                >
                  Site Location
                </th>
              </tr>
              <tr
                v-if="
                  preventiveArr &&
                  preventiveArr.property &&
                  preventiveArr.property.property_address
                "
              >
                <!-- <td class="font-size-18 font-weight-500 py-2 d-flex">
                      Site Location
                    </td> -->
                <td class="font-size-18 py-2" colspan="2">
                  <template
                    v-if="
                      preventiveArr &&
                      preventiveArr.property &&
                      preventiveArr.property.property_address
                    "
                  >
                    {{ getFormattedProperty(preventiveArr.property) }}
                  </template>
                  <template v-else>
                    <em class="text-muted"> no site location </em>
                  </template>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </template>
      <template v-slot:header-title>
        <div class="d-flex align-center">
          <span
            class="color-custom-blue font-weight-700 font-size-20 mr-2"
            v-if="
              preventiveArr &&
              preventiveArr.customer &&
              preventiveArr.customer.display_name
            "
            >{{ preventiveArr.customer.display_name }}</span
          >
        </div>
      </template>
      <template v-slot:header-action>
        <div class="d-flex align-center justify-end">
          <!--  <template
            v-if="
              preventiveArr &&
              preventiveArr.preventive &&
              preventiveArr.preventive.visit_create_flag == 0 && false
            "
          >
            <v-tooltip top content-class="custom-top-tooltip">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  :disabled="pageLoading"
                  class="mx-2 custom-bold-button white--text"
                  color="cyan"
                  depressed
                  v-bind="attrs"
                  v-on="on"
                  :to="
                    getDefaultRoute('preventive.create', {
                      query: { preventive: preventive },
                    })
                  "
                >
                  <v-icon left>mdi-pencil</v-icon> Edit
                </v-btn>
              </template>
              <span>Click here to edit</span>
            </v-tooltip>
          </template> -->
          <template
            v-if="
              preventiveArr?.preventive?.status != 4 &&
              preventiveArr?.preventive?.status != 1 &&
              preventiveArr?.preventive?.status != 5
            "
          >
            <v-tooltip bottom content-class="custom-bottom-tooltip">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  depressed
                  :disabled="pageLoading"
                  class="mx-2 custom-bold-button white--text"
                  color="red"
                  v-bind="attrs"
                  v-on="on"
                  v-on:click="confirm_dialog = true"
                >
                  <v-icon left>mdi-update</v-icon> Terminate
                </v-btn>
              </template>
              <span>Terminate Warranty</span>
            </v-tooltip>
          </template>
          <template
            v-if="
              preventiveArr &&
              preventiveArr.preventive &&
              preventiveArr?.preventive?.status == 1
            "
          >
            <v-tooltip top content-class="custom-top-tooltip">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  :disabled="pageLoading"
                  class="mx-2 custom-bold-button white--text"
                  color="cyan"
                  depressed
                  v-bind="attrs"
                  v-on="on"
                  :to="
                    getDefaultRoute('preventive.create', {
                      query: { preventive: preventive },
                    })
                  "
                >
                  <v-icon left>mdi-pencil</v-icon> Edit
                </v-btn>
              </template>
              <span>Click here to edit</span>
            </v-tooltip>
          </template>
          <v-btn
            depressed
            class="mx-2 custom-grey-border custom-bold-button"
            v-on:click="goBack()"
          >
            <v-icon small left>mdi-keyboard-backspace</v-icon>
            Back
          </v-btn>
        </div>
      </template>
      <template v-slot:body>
        <perfect-scrollbar
          :options="{ suppressScrollX: true }"
          class="scroll"
          style="
            max-height: calc(100vh - 185px);
            height: calc(100vh - 185px);
            position: relative;
          "
        >
          <v-container fluid class="pa-0">
            <v-row>
              <v-col md="12" class="pt-1">
                <v-tabs
                  active-class="custom-tab-active"
                  v-model="contractTab"
                  background-color="transparent"
                  color="cyan"
                  class="custom-tab-transparent tab-sticky"
                >
                  <v-tab
                    class="font-size-16 font-weight-600 px-8"
                    href="#overview"
                  >
                    <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                      <!--begin::Svg Icon-->
                      <inline-svg
                        :src="$assetURL('media/custom-svg/product.svg')"
                      />
                      <!--end::Svg Icon-->
                    </span>
                    Overview
                  </v-tab>
                  <v-tab
                    class="font-size-16 font-weight-600 px-8"
                    href="#line-item"
                  >
                    <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                      <!--begin::Svg Icon-->
                      <inline-svg
                        :src="$assetURL('media/custom-svg/line-item.svg')"
                      />
                      <!--end::Svg Icon-->
                    </span>
                    Line Items
                  </v-tab>
                  <v-tab
                    class="font-size-16 font-weight-600 px-8"
                    href="#schedule"
                  >
                    <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                      <!--begin::Svg Icon-->
                      <inline-svg
                        :src="$assetURL('media/custom-svg/product.svg')"
                      />
                      <!--end::Svg Icon-->
                    </span>
                    PM Schedule
                  </v-tab>
                  <v-tab
                    class="font-size-16 font-weight-600 px-8"
                    href="#attachment"
                  >
                    <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                      <!--begin::Svg Icon-->
                      <inline-svg
                        :src="$assetURL('media/custom-svg/attachment.svg')"
                      />
                      <!--end::Svg Icon-->
                    </span>
                    Attachment
                  </v-tab>
                  <v-tab
                    class="font-size-16 font-weight-600 px-8"
                    href="#history"
                  >
                    <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                      <!--begin::Svg Icon-->
                      <inline-svg
                        :src="$assetURL('media/custom-svg/history.svg')"
                      />
                      <!--end::Svg Icon-->
                    </span>
                    History
                  </v-tab>
                </v-tabs>
                <v-tabs-items v-model="contractTab">
                  <v-tab-item value="attachment">
                    <AdditionalDocuments
                      :type-id="preventiveArr?.preventive?.id"
                      class="mx-4"
                      type-text="Preventive Maintenance"
                      :type="56"
                      :extensive="false"
                    ></AdditionalDocuments>
                  </v-tab-item>
                  <v-tab-item value="overview">
                    <v-layout class="my-4">
                      <v-flex md4 class="mr-2 custom-border-right">
                        <v-card
                          flat
                          class="custom-grey-border remove-border-radius mb-4"
                        >
                          <v-card-title class="headline grey lighten-4">
                            <span
                              class="font-weight-700 custom-headline color-custom-blue font-size-16"
                              >Customer Detail</span
                            >
                          </v-card-title>
                          <v-card-text class="p-6 font-size-16">
                            <table
                              class="width-100"
                              v-if="preventiveArr && preventiveArr.customer"
                            >
                              <!--  <tr>
                            <th
                              colspan="2"
                              class="font-size-19 color-custom-blue grey lighten-4 py-2"
                            >
                              Customer Detail
                            </th>
                          </tr> -->
                              <template>
                                <tr>
                                  <td
                                    class="pb-2 font-size-18 font-weight-500 text-capitalize"
                                    style="width: 150px"
                                  >
                                    Type
                                  </td>
                                  <td
                                    class="pb-2 font-size-18 text-capitalize"
                                    v-if="
                                      preventiveArr &&
                                      preventiveArr &&
                                      preventiveArr.customer
                                    "
                                  >
                                    <span class="fw-500 no-wrap">
                                      <v-chip
                                        color="blue white--text"
                                        label
                                        small
                                        v-if="
                                          preventiveArr.customer.company_type ==
                                          'company'
                                        "
                                      >
                                        <span class="text-uppercase">
                                          Company
                                        </span>
                                      </v-chip>
                                      <v-chip
                                        color="red white--text"
                                        label
                                        v-if="
                                          preventiveArr.customer.company_type ==
                                          'personal'
                                        "
                                      >
                                        <span
                                          class="font-size-16 font-weight-500"
                                        >
                                          Individual
                                        </span>
                                      </v-chip>
                                    </span>
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    class="pb-2 font-size-18 font-weight-500 text-capitalize"
                                  >
                                    Company Name
                                  </td>
                                  <td
                                    class="pb-2 font-size-18 text-capitalize"
                                    v-if="
                                      preventiveArr &&
                                      preventiveArr &&
                                      preventiveArr.customer &&
                                      preventiveArr.customer.company_name
                                    "
                                  >
                                    {{ preventiveArr.customer.company_name }}
                                  </td>
                                  <td v-else>
                                    <em class="text-muted"> no Company Name</em>
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    class="pb-2 font-size-18 font-weight-500 text-capitalize"
                                  >
                                    Company Email
                                  </td>
                                  <td
                                    class="pb-2 font-size-18"
                                    v-if="
                                      preventiveArr &&
                                      preventiveArr &&
                                      preventiveArr.customer &&
                                      preventiveArr.customer.company_email
                                    "
                                  >
                                    <span class="fw-500 no-wrap">
                                      {{ preventiveArr.customer.company_email }}
                                    </span>
                                  </td>
                                  <td v-else>
                                    <em class="text-muted">
                                      no Company Email</em
                                    >
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    class="pb-2 font-size-18 font-weight-500 text-capitalize"
                                  >
                                    Company Number
                                  </td>
                                  <!--   <pre>{{ customer }}</pre> -->
                                  <td
                                    class="pb-2 font-size-18 text-capitalize"
                                    v-if="
                                      preventiveArr &&
                                      preventiveArr &&
                                      preventiveArr.customer &&
                                      preventiveArr.customer.company_number
                                    "
                                  >
                                    {{ preventiveArr.customer.company_number }}
                                  </td>
                                  <td v-else>
                                    <em class="text-muted">
                                      no Company Number</em
                                    >
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    class="pb-2 font-size-18 font-weight-500 text-capitalize"
                                  >
                                    Company Fax
                                  </td>
                                  <!--   <pre>{{ customer }}</pre> -->
                                  <td
                                    class="pb-2 font-size-18 text-capitalize"
                                    v-if="
                                      preventiveArr &&
                                      preventiveArr &&
                                      preventiveArr.customer &&
                                      preventiveArr.customer.company_fax
                                    "
                                  >
                                    {{ preventiveArr.customer.company_fax }}
                                  </td>
                                  <td v-else>
                                    <em class="text-muted"> no Company Fax</em>
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    class="pb-2 font-size-18 font-weight-500 text-capitalize"
                                  >
                                    UEN
                                  </td>
                                  <!--   <pre>{{ customer }}</pre> -->
                                  <td
                                    class="pb-2 font-size-18 text-capitalize"
                                    v-if="
                                      preventiveArr &&
                                      preventiveArr &&
                                      preventiveArr.customer &&
                                      preventiveArr.customer.uen
                                    "
                                  >
                                    {{ preventiveArr.customer.uen }}
                                  </td>
                                  <td v-else>
                                    <em class="text-muted"> no UEN</em>
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    class="pb-2 font-size-18 font-weight-500 text-capitalize"
                                  >
                                    Website
                                  </td>
                                  <!--   <pre>{{ customer }}</pre> -->
                                  <td
                                    class="pb-2 font-size-18 text-capitalize"
                                    v-if="
                                      preventiveArr &&
                                      preventiveArr &&
                                      preventiveArr.customer &&
                                      preventiveArr.customer.website
                                    "
                                  >
                                    {{ preventiveArr.customer.website }}
                                  </td>
                                  <td v-else>
                                    <em class="text-muted"> no Website</em>
                                  </td>
                                </tr>

                                <!-- <tr>
                              <td
                                valign="middle"
                                class="py-1 font-size-18 font-weight-600"
                                style="width: 140px"
                              >
                                Description
                              </td>
                              <td
                                valign="middle"
                                class="py-1 font-size-18 font-weight-500 d-flex"
                              >
                                <label
                                  class="m-0"
                                  v-if="
                                    preventiveArr &&
                                    preventiveArr.preventive &&
                                    preventiveArr.preventive.remark 
                                  "
                                >
                                  {{ preventiveArr.preventive.remark }}
                                </label>
                                <em v-else class="text--secondary">
                                  no Description
                                </em>
                              </td>
                            </tr> -->
                              </template>
                            </table>
                          </v-card-text>
                        </v-card>
                      </v-flex>
                      <v-flex md8>
                        <v-card
                          flat
                          class="custom-grey-border remove-border-radius mb-4"
                        >
                          <v-card-title class="headline grey lighten-4">
                            <span
                              class="font-weight-700 custom-headline color-custom-blue font-size-16"
                              >Description</span
                            >
                          </v-card-title>
                          <v-card-text class="p-6 font-size-16">
                            <read-more
                              class="mx-4 custom-read-more font-weight-600 font-size-18 pb-1 custom-gray-color"
                              more-str="read more"
                              :text="
                                preventiveArr?.preventive?.remark ||
                                '<em>No Description</em>'
                              "
                              link="#"
                              less-str="read less"
                              :max-chars="250"
                            >
                            </read-more>
                          </v-card-text>
                        </v-card>
                        <v-card
                          flat
                          class="custom-grey-border remove-border-radius mb-4"
                        >
                          <v-card-title class="headline grey lighten-4">
                            <span
                              class="font-weight-700 custom-headline color-custom-blue font-size-16"
                              >Terminated Remark</span
                            >
                          </v-card-title>
                          <v-card-text class="p-6 font-size-16">
                            <read-more
                              class="mx-4 custom-read-more font-weight-600 font-size-18 pb-1 custom-gray-color"
                              more-str="read more"
                              :text="
                                preventiveArr?.preventive?.terminate_reason ||
                                '<em>No Terminated Remark</em>'
                              "
                              link="#"
                              less-str="read less"
                              :max-chars="150"
                            >
                            </read-more>
                          </v-card-text>
                        </v-card>
                      </v-flex>
                    </v-layout>
                  </v-tab-item>
                  <v-tab-item value="line-item">
                    <VisitLineItem
                      :show-price="preventiveArr?.preventive?.show_price"
                      :discount-level="
                        preventiveArr?.preventive?.discount_type == 2
                          ? 'line_item'
                          : 'transaction'
                      "
                      :related-id="preventiveArr?.preventive?.id"
                      :related-type="7"
                    />
                    <div class="mt-4 pr-2 font-weight-600">
                      <table width="100%">
                        <tr>
                          <td width="66%"></td>
                          <td width="18%" align="right">Sub Total</td>
                          <td width="18%" align="right">
                            {{
                              formatMoney(preventiveArr?.preventive?.sub_total)
                            }}
                          </td>
                        </tr>
                        <tr
                          v-if="preventiveArr?.preventive?.discount_type == 1"
                        >
                          <td></td>
                          <td align="right">
                            Discount
                            <span
                              v-if="
                                preventiveArr?.preventive
                                  ?.discount_value_type == 1
                              "
                              >({{
                                formatMoney(
                                  preventiveArr?.preventive?.discount_value
                                )
                              }})</span
                            >
                            <span
                              v-if="
                                preventiveArr?.preventive
                                  ?.discount_value_type == 2
                              "
                              >({{
                                formatNumber(
                                  preventiveArr?.preventive?.discount_value
                                )
                              }}%)</span
                            >
                          </td>
                          <td align="right">
                            {{
                              formatMoney(
                                preventiveArr?.preventive?.discount_amount
                              )
                            }}
                          </td>
                        </tr>
                        <tr v-if="preventiveArr?.preventive?.tax_applied">
                          <td></td>
                          <td align="right">
                            Tax [GST
                            {{ preventiveArr?.preventive?.tax_value }}%]
                          </td>
                          <td align="right">
                            {{
                              formatMoney(preventiveArr?.preventive?.tax_amount)
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td></td>
                          <td align="right">Adjustment</td>
                          <td align="right">
                            {{
                              formatMoney(preventiveArr?.preventive?.adjustment)
                            }}
                          </td>
                        </tr>
                        <tr class="font-size-19">
                          <td></td>
                          <td align="right">Grand Total</td>
                          <td align="right">
                            {{ formatMoney(preventiveArr?.preventive?.total) }}
                          </td>
                        </tr>
                      </table>
                    </div>
                  </v-tab-item>
                  <v-tab-item value="schedule">
                    <v-simple-table
                      class="inner-simple-table custom-border-top"
                      fixed-header
                    >
                      <template v-slot:default>
                        <thead
                          style="
                            background-color: rgb(243, 246, 249);
                            color: rgb(36, 50, 109);
                          "
                        >
                          <tr class="custom-border-bottom">
                            <td class="font-size-16 font-weight-700">
                              Schedule #
                            </td>
                            <td class="font-size-16 font-weight-700">
                              Days Of Week
                            </td>
                            <td class="font-size-16 font-weight-700">
                              Planned Date / Time
                            </td>
                            <!--   <td class="font-size-16 font-weight-700">
                              Start Time
                            </td> -->
                            <td class="font-size-16 font-weight-700">
                              Actual Date / Time
                            </td>
                            <td class="font-size-16 font-weight-700">
                              PM Done
                            </td>
                            <td class="font-size-16 font-weight-700">
                              No. Of EQP Serviced
                            </td>
                            <td class="font-size-16 font-weight-700">
                              Visit Action
                            </td>
                            <td
                              class="font-size-16 font-weight-700"
                              width="30%"
                            >
                              Visit #
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          <template
                            v-if="
                              lodash.isEmpty(preventiveArr?.visitSchedule) ===
                              false
                            "
                          >
                            <tr
                              v-for="(
                                data, index
                              ) in preventiveArr.visitSchedule"
                              :key="index"
                              :class="{ 'orange_lighten-5': index % 2 == 0 }"
                            >
                              <td class="py-2">
                                <v-chip
                                  class="mr-2 font-weight-600"
                                  color="chip-custom-blue"
                                  label
                                  small
                                  outlined
                                >
                                  {{ data?.barcode }}
                                </v-chip>
                              </td>
                              <td>
                                <span class="font-weight-500">
                                  {{ data.daysOfWeek }}
                                </span>
                              </td>

                              <td class="py-2">
                                <div class="quotation-listing-amount">
                                  <p class="m-0 custom-nowrap-ellipsis">
                                    <b>Start: </b>
                                    <span class="font-weight-500"
                                      >{{ formatDate(data.startDate) }}
                                      {{ data.startTime }}
                                    </span>
                                  </p>
                                  <p class="m-0 custom-nowrap-ellipsis">
                                    <b>End: </b>
                                    <span class="font-weight-500"
                                      >{{ formatDate(data.endDate) }}
                                      {{ data.endTime }}
                                    </span>
                                  </p>
                                </div>
                              </td>
                              <!-- <td class="py-2">
                                <span class="font-weight-500"
                                  >{{ data.startTime }}
                                </span>
                              </td> -->
                              <td>
                                <div class="quotation-listing-amount">
                                  <p class="m-0 custom-nowrap-ellipsis">
                                    <b>Start: </b>
                                    <span
                                      class="font-weight-500"
                                      v-if="data?.schedule_visit?.length > 0"
                                      >{{
                                        formatDate(
                                          getActualStartDate(
                                            data?.schedule_visit
                                          )
                                        )
                                      }}
                                      {{
                                        formatDateStartTime(
                                          getActualStartDate(
                                            data?.schedule_visit
                                          )
                                        )
                                      }}
                                    </span>
                                    <em class="text-muted" v-else
                                      >No Start Date</em
                                    >
                                    <!--  {{getActualDate(data?.schedule_visit)}} -->
                                  </p>
                                  <p class="m-0 custom-nowrap-ellipsis">
                                    <b>End: </b>
                                    <span
                                      class="font-weight-500"
                                      v-if="data?.schedule_visit?.length > 0"
                                      >{{
                                        formatDate(
                                          getActualEndDate(data?.schedule_visit)
                                        )
                                      }}
                                      {{
                                        formatDateStartTime(
                                          getActualEndDate(data?.schedule_visit)
                                        )
                                      }}
                                    </span>
                                    <em class="text-muted" v-else>
                                      No End Date</em
                                    >
                                  </p>
                                </div>
                              </td>
                              <td class="py-2">
                                <span class="font-weight-500">
                                  <v-icon
                                    v-if="data.pm_done"
                                    size="20"
                                    :color="
                                      data.visit_flag == 1 ? 'green' : 'blue'
                                    "
                                  >
                                    mdi-check-all</v-icon
                                  >
                                  <em class="text-muted" v-else> No</em>
                                </span>
                              </td>
                              <td class="py-2">
                                <span
                                  class="font-weight-600"
                                  style="font-size: 15px"
                                >
                                  <span class="green--text"
                                    ><template v-if="data.items_count">
                                      {{ data.items_count }}</template
                                    >
                                    <template v-else> 0</template>
                                  </span>
                                  /
                                  <span class="red--text">{{
                                    preventiveArr?.itemsIds?.length
                                  }}</span>
                                </span>
                              </td>
                              <!-- <td class="py-2">
                                <span class="font-weight-500"
                                  >{{ data.endTime }}
                                </span>
                              </td> -->
                              <td class="py-2">
                                <!--  <v-icon
                                  size="20"
                                  :color="data.visit_id > 0 ? 'green' : 'red'"
                                >
                                  mdi-flag</v-icon
                                > -->
                                <v-tooltip
                                  v-if="
                                    preventiveArr?.preventive?.status != 1 &&
                                    preventiveArr?.preventive?.status != 4
                                  "
                                  top
                                  content-class="custom-top-tooltip"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      v-bind="attrs"
                                      v-on="on"
                                      class="mx-2 custom-bold-button white--text"
                                      color="cyan"
                                      tile
                                      depressed
                                      v-on:click="createJob(data)"
                                    >
                                      <v-icon>mdi-plus</v-icon>
                                    </v-btn>
                                  </template>
                                  <span>Create Visit</span>
                                </v-tooltip>
                                <em v-else class="text-muted"> no action</em>
                              </td>
                              <td class="py-2" width="30%">
                                <span
                                  class="font-weight-700"
                                  v-if="
                                    data &&
                                    data.schedule_visit &&
                                    data?.schedule_visit?.length > 0
                                  "
                                >
                                  <template
                                    v-for="(row, index) in data?.schedule_visit"
                                  >
                                    <v-tooltip
                                      top
                                      content-class="custom-top-tooltip"
                                      :key="'ticket-type' + index"
                                    >
                                      <template
                                        v-slot:activator="{ on, attrs }"
                                      >
                                        <v-chip
                                          v-bind="attrs"
                                          v-on="on"
                                          class="mr-2 font-weight-600"
                                          :color="
                                            getVisitColor(row?.visit?.status)
                                          "
                                          label
                                          small
                                          outlined
                                          @click="routeToDetail(row?.visit?.id)"
                                        >
                                          {{ row?.visit?.barcode }}
                                        </v-chip>
                                      </template>
                                      <span>{{
                                        getVisitStatusText(row?.visit?.status)
                                      }}</span>
                                    </v-tooltip>
                                  </template>
                                </span>
                                <em v-else class="text-muted"> no visit </em>
                              </td>
                            </tr>
                          </template>
                          <template v-else>
                            <tr>
                              <td :colspan="6">
                                <p
                                  class="m-0 row-not-found text-center font-weight-500 font-size-16"
                                >
                                  Sorry! No Schedule(s) Found.
                                </p>
                              </td>
                            </tr>
                          </template>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-tab-item>
                  <v-tab-item :value="'history'">
                    <template
                      v-if="
                        preventiveArr &&
                        preventiveArr.preventive &&
                        preventiveArr.preventive.id
                      "
                    >
                      <InternalHistoryDetail
                        type="preventive-maintanance"
                        :type_id="preventiveArr.preventive.id"
                      >
                      </InternalHistoryDetail>
                    </template>
                  </v-tab-item>
                </v-tabs-items>
              </v-col>
            </v-row>
          </v-container>
        </perfect-scrollbar>
      </template>
      <template v-slot:footer> </template>
    </DetailTemplate>
    <Dialog :common-dialog="confirm_dialog">
      <template v-slot:title> Confirmation </template>
      <template v-slot:body>
        <v-form ref="PreMForm" v-model.trim="formValid" lazy-validation>
          <v-container class="px-10" fluid>
            <p class="font-weight-600 font-size-19">
              Are you sure, you want to
              <span class="font-weight-700">Terminate</span>?
            </p>
            <label
              for="cancel-reason"
              class="font-weight-600 font-size-18 required"
              >Reason</label
            >
            <TextAreaField
              auto-grow
              dense
              filled
              color="cyan"
              v-model="actionData.terminate_reason"
              :rules="[
                validateRules.required(actionData.terminate_reason, 'Reason'),
              ]"
              placeholder="Enter Reason..."
              solo
              flat
              row-height="25"
              counter="200"
            />
            <label for="cancel-reason" class="font-weight-600 font-size-18"
              >Attachments</label
            >
            <file-upload v-model="actionData.attachments" :maxLimit="5">
            </file-upload>
          </v-container>
        </v-form>
      </template>
      <template v-slot:action>
        <template>
          <v-btn
            v-on:click="terminatePm()"
            :disabled="!formValid || pageLoading"
            :loading="pageLoading"
            class="mx-2 custom-bold-button white--text"
            color="cyan"
          >
            Yes! Continue
          </v-btn>
        </template>
        <v-btn
          :disabled="pageLoading"
          v-on:click="confirm_dialog = false"
          class="mx-2 custom-grey-border custom-bold-button"
        >
          Cancel
        </v-btn>
      </template>
    </Dialog>
  </div>
</template>
<script>
import DetailTemplate from "@/view/pages/partials/Detail-Template.vue";
import CommonMixin from "@/core/plugins/common-mixin";
import ValidationMixin from "@/core/plugins/validation-mixin";
import { GET, POST, CLEAR_ERROR } from "@/core/services/store/request.module";
import TextAreaField from "@/view/components/app-component/TextAreaField.vue";

/* import {
  SET_PREVENTIVE_VISIT
} from "@/core/services/store/preventive.module"; */
import AdditionalDocuments from "@/view/pages/engineer/Additional-Documents";
import InternalHistoryDetail from "@/view/pages/partials/Detail/Internal-History-Detail.vue";
import moment from "moment";
import VisitLineItem from "@/view/components/ViewLineItem.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import CustomStatus from "@/view/pages/partials/CustomStatus.vue";
import FileUpload from "@/view/components/app-component/FileInput";
import Dialog from "@/view/pages/partials/Dialog";

export default {
  mixins: [CommonMixin, ValidationMixin],
  name: "customer-detail",
  title: "Customer",
  data() {
    return {
      pageLoading: false,
      contractTab: "overview",
      preventive: 0,
      preventiveArr: {},
      formValid: true,
      confirm_dialog: false,
      /* statusLoading:false, */
      actionData: {},
      lastVisitData: {},
      pmVisitArray: [],
    };
  },
  components: {
    DetailTemplate,
    AdditionalDocuments,
    InternalHistoryDetail,
    VisitLineItem,
    CustomStatus,
    "file-upload": FileUpload,
    Dialog,
    TextAreaField,
  },
  methods: {
    terminatePm() {
      const _this = this;
      if (!_this.$refs.PreMForm.validate()) {
        return false;
      }
      _this.$store.dispatch(CLEAR_ERROR, {});
      _this.pageLoading = true;
      let formData = this.actionData;
      let requestTYPE = POST;
      let requestURL = `preventive-maintanance-terminate/${this.preventive}`;
      this.$store
        .dispatch(requestTYPE, {
          url: requestURL,
          data: formData,
        })
        .then(() => {
          _this.confirm_dialog = false;
        })
        .catch((error) => {
          console.log({ error });
        })
        .finally(() => {
          this.pageLoading = false;
        });
    },
    getActualStartDate(row) {
      let LastStartDate = row[row.length - 1];
      return LastStartDate?.visit?.started_at;
    },
    getActualEndDate(row) {
      let LastStartDate = row[row.length - 1];
      return LastStartDate?.visit?.finished_at;
    },
    getVisitColor(data) {
      if (data) {
        if (data == 1) {
          return "blue";
        } else if (data == 2) {
          return "yellow";
        } else if (data == 3) {
          return "red";
        } else if (data == 4) {
          return "green";
        } else if (data == 5) {
          return "red";
        } else if (data == 6) {
          return "cyan";
        }
      }
    },
    getVisitStatusText(data) {
      if (data) {
        if (data == 1) {
          return "Open";
        } else if (data == 2) {
          return "In-Progress";
        } else if (data == 3) {
          return "Hold & Stay Assign";
        } else if (data == 4) {
          return "Completed";
        } else if (data == 5) {
          return "Cancelled";
        } else if (data == 6) {
          return "Hold & Unassigned";
        }
      }
    },
    /* formatDateTimeStart(row) {
      return moment(row).format("hh:mm A");
    }, */
    formatDateStartTime(row) {
      return moment(row).format("hh:mm A");
    },
    /* createJob(data) {
      this.$router.push(
        this.getDefaultRoute("job.create", {
          query: {
            ticket: this.preventiveArr?.preventive?.ticket,
            priventive: this.preventive,
            schedule_id: this.lodash.toSafeInteger(data?.id ?? 0),
          },
        })
      );
    }, */
    createJob(data) {
      this.pmVisitArray = [
        {
          pm_id: this.preventive,
          scheduleId: this.lodash.toSafeInteger(data?.id ?? 0),
          iteam: this.preventiveArr?.itemsIds,
          showSchedule: 1,
        },
      ];
      this.$router.push(
        this.getDefaultRoute("job.create", {
          query: {
            pmSchedule: JSON.stringify(this.pmVisitArray),
            type: "multiple",
          },
        })
      );
    },
    routeToDetail(id) {
      this.$router.push(
        this.getDefaultRoute("visit.detail", {
          params: { id: id },
        })
      );
    },
    remaningDate(date) {
      var a = moment(date.end_date);
      var b = moment(date.start_date);

      var years = a.diff(b, "year");
      b.add(years, "years");

      var months = a.diff(b, "months");
      b.add(months, "months");

      var days = a.diff(b, "days");
      if (days == 0) {
        days = "";
      } else {
        days = days + " Days";
      }
      if (months == 0) {
        months = "";
      } else {
        months = months + " Months";
      }
      if (years == 0) {
        years = "";
      } else {
        years = years + " Years";
      }

      return years + months + days;
    },
    getFormattedProperty(row) {
      let tempArr = [];
      if (row.unit_no) {
        tempArr.push(row.unit_no);
      }
      if (row.street_1) {
        tempArr.push(row.street_1);
      }
      if (row.street_2) {
        tempArr.push(row.street_2);
      }

      if (row.country) {
        tempArr.push(row.country);
      }
      return tempArr.join(", ");
    },
    totalDays(date) {
      var a = moment(date.end_date);
      var b = moment(date.start_date);
      var final = a.diff(b, "days"); // =1
      return final;
    },
    getStatus(status) {
      if (status) {
        if (status == 1) {
          return "Draft";
        } else if (status == 2) {
          return "Active";
        } else if (status == 3) {
          return "Extended";
        } else if (status == 4) {
          return "Terminated";
        } else if (status == 5) {
          return "Expired";
        }
      }
    },
    getColor(status) {
      if (status) {
        if (status == 1) {
          return "cyan white--text";
        } else if (status == 2) {
          return "green white--text";
        } else if (status == 3) {
          return "orange white--text";
        } else if (status == 4) {
          return "red white--text";
        } else if (status == 5) {
          return "teal white--text";
        }
      }
    },
    getPreventive() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(GET, {
          url: "preventive/" + _this.preventive,
        })
        .then(({ data }) => {
          _this.preventiveArr = data;
          if (this.preventiveArr?.preventive?.show_price) {
            this.preventiveArr.preventive.show_price =
              this.preventiveArr?.preventive?.show_price > 0 ? true : false;
          }
          _this.$store.dispatch(SET_BREADCRUMB, [
            {
              title: "Preventive Maintenance",
              route: "preventive-maintanance",
            },
            { title: "Detail" },
            { barcode: data.barcode },
          ]);
          /*   _this.warrantyMoreAction = data.more_actions; */
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
  },
  mounted() {
    if (this.preventive > 0) {
      this.getPreventive();
    }
  },
  created() {
    const _this = this;
    _this.preventive = _this.lodash.toSafeInteger(_this.$route.params.id);
  },
};
</script>
